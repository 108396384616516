import React, { ReactElement, useCallback, useState } from 'react'
import './MdorView.scss'
import Helmet from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import Viewer from './Viewer/Viewer'
import getMdor from '../../services/getMdor'
import Form, {
  FormContent,
  FormInput,
  useFormState,
} from '../../components/Form/Form'
import MdorLoginValidation from './MdorView.validation'
import Logo from '../../assets/svg/avida-logo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

let captcha: any

const MdorLogin = (): ReactElement => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const [mdorFileToken, setMdorFileToken] = useState<string>('')
  const [isButtonShown, setIsButtonShown] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [mdorFile, setMdorFile] = useState<string>('')
  const [isAgreeChecked, setIsAgreeChecked] = useState<boolean>(false)
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState<boolean>(false)
  const [isShowErrorTermsAndCondition, setIsShowErrorTermsAndCondition] =
    useState<boolean>(false)
  const [isShowErrorRecaptcha, setIsShowErrorRecaptcha] =
    useState<boolean>(false)
  const [recaptcha, setRecaptcha] = useState<string | null>('')

  const {
    isFormDisplayed,
    values,
    errors,
    onFieldValueChange,
    onFieldBlur,
    validateFields,
    resetFields,
  } = useFormState({
    validation: MdorLoginValidation,
  })
  const handleSubmit = useCallback(async () => {
    setIsShowErrorTermsAndCondition(false)
    setIsShowErrorRecaptcha(false)
    setIsLoading(true)

    if (!isAgreeChecked) {
      setIsShowErrorTermsAndCondition(true)
    }

    if (!isRecaptchaChecked) {
      setIsShowErrorRecaptcha(true)
    }

    if (!isAgreeChecked || !isRecaptchaChecked) {
      return
    }

    const fieldErrors = validateFields(values)

    if (!Object.keys(fieldErrors).length) {
      const { companyCode, contractNo } = values
      const {
        file,
        token,
        error: requestError,
      } = await getMdor({
        companyCode,
        contractNo,
        brand: 'AVIDA',
        recaptchaToken: recaptcha || '',
      })

      if (file) {
        setMdorFile(file)
        setMdorFileToken(token as string)
        setIsLoading(false)
      } else {
        setError(
          requestError || 'Invalid credential. Please contact Customer Service',
        )
        setIsLoading(false)
      }

      // captcha.reset()
      setIsRecaptchaChecked(false)
      setIsAgreeChecked(false)
      resetFields()
    }
  }, [
    isAgreeChecked,
    isRecaptchaChecked,
    validateFields,
    values,
    recaptcha,
    resetFields,
  ])

  if (mdorFile) {
    return <Viewer pdfFile={mdorFile} mdorFileToken={mdorFileToken} />
  }

  return (
    <div className="mdor-login">
      <Helmet>
        <title>MDOR Download</title>
        <link rel="canonical" href={process.env.GATSBY_SITE_URL} />
      </Helmet>
      <div className="mdor-login-content">
        <div className="mdor-login-form-container">
          <LazyLoadImage
            src={Logo}
            alt="banner image"
            className="mdor-login-company-logo"
          />
          <p className="mdor-login-title">MDDOR Portal</p>
          <span className="mdor-login-form-error">{!loading && error}</span>
          <Form onSubmit={handleSubmit} display={isFormDisplayed}>
            <FormInput
              id="companyCode"
              name="companyCode"
              placeholder="Enter Company Code"
              onChange={onFieldValueChange}
              onBlur={onFieldBlur}
              error={errors.companyCode}
            />
            <FormInput
              id="contractNo"
              name="contractNo"
              placeholder="Enter Contract Number"
              onChange={onFieldValueChange}
              onBlur={onFieldBlur}
              error={errors.contractNo}
            />
            <div className="i-agree-container">
              <label htmlFor="i-agree">
                <input
                  id="i-agree"
                  type="checkbox"
                  onChange={(): void => {
                    setIsAgreeChecked(!isAgreeChecked)
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/role-has-required-aria-props */}
                <span role="checkbox" />
              </label>
              We use cookies to improve your browsing experience. By continuing
              to browse this site, you understood and agree to our{' '}
              <a href="/privacy-notice" target="_blank">
                Privacy Notice
              </a>
              {isShowErrorTermsAndCondition && !isAgreeChecked && (
                <p>You must agree to the Terms and Conditions.</p>
              )}
            </div>
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_API_KEY || ''}
                onChange={(value: string | null): void => {
                  setRecaptcha(value)
                  setIsRecaptchaChecked(!!value)
                }}
                /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type, no-return-assign */
                ref={(e) => (captcha = e)}
              />
              {isShowErrorRecaptcha && !isRecaptchaChecked && (
                <p>Please verify that you are not a robot.</p>
              )}
            </div>
            <FormContent>
              <button type="submit" className="mdor-login-form-submit">
                Login
              </button>
              <p className="mdor-login-support-link">
                Can’t log in?{' '}
                <button
                  type="button"
                  onClick={(): void => setIsButtonShown(!isButtonShown)}
                >
                  Contact the Customer Service
                </button>
              </p>
            </FormContent>
          </Form>
        </div>
        <div className="mdor-login-footer">
          © 2020 Avida Land <br />
          DESIGNED BY WHITE CLOAK TECHNOLOGIES
        </div>
      </div>
    </div>
  )
}

export default MdorLogin
